import {useEffect, useState} from "react";
import {MovieDb} from "moviedb-promise";
import {useParams} from "react-router-dom";
import "../css/Movie.css"
import Pairings from "./Pairings";
import {Nav, Spinner, Stack} from "react-bootstrap";
// const moviedb = new MovieDb('27a8b3cd941691251e2e623dfdd25d91');
const moviedb = new MovieDb(process.env.REACT_APP_TMDB_API_KEY);
function Movie(props) {

    const {id} = useParams();
    const [movie, setMovie] = useState(null);
    const [pairings, setPairings] = useState(null);

    const tmdbUrlback = "https://image.tmdb.org/t/p/w1280/";
    const tmdbUrlposter = "https://image.tmdb.org/t/p/w185/";


    //On component load, get movie by id passed into props
    useEffect(() => {
        moviedb.movieInfo(id)
            .then((res) => {
                setMovie(res)
            })
            .catch(console.error)

    }, []);


    return (
        <div id={"Movie"}>
            {
                (!!movie) ?
                    <div className="pb-3">
                        <div className="d-inline-flex flex-column pairing-card w-75 justify-content-center text-center mx-auto py-2 py-lg-5">
                            <hr />
                            <Stack direction={"horizontal"} gap={3} className="justify-content-around whole-card">
                                <Nav.Link href={`/movie/${movie.id}`}><img src={`${tmdbUrlposter}${movie.poster_path}`} className="movie-poster" alt={"Movie Poster"} /></Nav.Link>

                                <div className="d-flex flex-column justify-content-between align-items-center movie-content">
                                    <h4 className="w-100 pair-title py-1">{movie.title}</h4>
                                    <h5>{movie.release_date.split("-")[0]}</h5>
                                    <p className="movie-description">{movie.tagline}</p>
                                    <p className="movie-description">{movie.overview}</p>
                                </div>
                            </Stack>
                        </div>
                        <div className="d-flex w-100 justify-content-center">
                            <hr />
                            <Pairings movie_id={movie.id} />
                        </div>

                        <div className="backdrop-full">
                            <img src={`${tmdbUrlback}${movie.backdrop_path}`} alt={"movie backdrop"} className="pairing-backdrop" />
                            <div className="backdrop-mask"></div>
                        </div>
                    </div>

                    :
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
            }
        </div>
    );
}

export default Movie;