import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {MovieDb} from "moviedb-promise";
import "../css/Pairing.css"
import {Spinner, Stack, Nav} from "react-bootstrap";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const moviedb = new MovieDb(process.env.REACT_APP_TMDB_API_KEY);
function Pairing(props){

    const {id} = useParams();

    const [movie, setMovie] = useState(null);
    const [pairing, setPairing] = useState(null);
    const [recipe, setRecipe] = useState(null);

    const tmdbUrl = "https://image.tmdb.org/t/p/w1280/";

    //on load, get pairing from url param
    useEffect(() => {
        const getPairing = async () => {
            const tempPairing = await axios.get(`${API_URL}/pairings/${id}`).then((response) => {return response.data});
            setPairing(tempPairing);
        }
        getPairing();

    }, []);

    //when pairing is retrieved, get movie and recipe for that pairing
    useEffect(() => {
        const getMovie = async () => {
            const tempMovie = await moviedb.movieInfo(pairing.movie_id);
            setMovie(tempMovie);
        }

        const getRecipe = async () => {
            const tempRecipe = await axios.get(`${API_URL}/recipes/${pairing.recipe_id}`).then((response) => {return response.data[0]});
            setRecipe(tempRecipe);
        }

        if (!!pairing) {
            getMovie();
            getRecipe();
        }
    },[pairing]);

    const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
    };

    return (
        <div id={"pairing"}>
            {
                (!!movie && !!pairing && !!recipe) ?
                    <div className="mx-auto">
                        <div className="d-inline-flex flex-column pairing-card">
                            <hr/>
                            <h4 className="w-100 pair-title py-1 text-center">{movie.title} & {recipe.label}</h4>
                            <p className="w-100 paired-by text-center">Paired by {pairing.user_id} on {new Date(pairing.date_created).toLocaleDateString(undefined,options)} </p>
                            <Stack direction={"horizontal"} id="pair-card" className="justify-content-between text-center">
                                <Nav.Link href={`/movie/${movie.id}`} className="m-poster"><img src={`${tmdbUrl}${movie.poster_path}`} className="movie-poster" alt={"Movie Poster"} /></Nav.Link>
                                <p className="pairing-description"> {pairing.description}</p>
                                <a href={recipe.url} className="r-picture"><img src={`data:image/jpeg;base64,${recipe.image}`} className="recipe-picture" alt="Recipe Photo"/></a>
                            </Stack>
                        </div>

                        <div id={"ingredients"} className="d-inline-flex flex-column w-100 align-items-center">
                            <hr className="w-75" />
                            <section><h4>Recipe From <a href={recipe.url}>{recipe.source}</a></h4></section>
                            <section className="py-3"><h5>Ingredients</h5></section>
                            <ul className="mt-2 mb-5 ingredient-list">
                                {
                                    recipe.ingredientLines.map(function (ingredient, i){
                                        return <li>{ingredient}</li>
                                    })
                                }
                            </ul>
                        </div>

                        <div className="backdrop-full">
                            <img src={`${tmdbUrl}${movie.backdrop_path}`} alt={"movie backrop"} className="pairing-backdrop" />
                            <div className="backdrop-mask"></div>
                        </div>
                    </div>

                :
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
            }
        </div>

    );
}

export default Pairing;