import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Form, ListGroup} from "react-bootstrap";
import {useState} from "react";
import {MovieDb} from "moviedb-promise";
// const moviedb = new MovieDb('27a8b3cd941691251e2e623dfdd25d91');
const moviedb = new MovieDb(process.env.REACT_APP_TMDB_API_KEY);
function MovieSearchModal(props) {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    function handleChange(e){
        setSearchTerm(e.target.value);
    }

    function movieSearch(e) {
        e.preventDefault();
        moviedb.searchMovie({query : searchTerm})
            .then((res) => {
                setSearchResults(res.results);
        })
    }

    function handleClick(e){
        props.setMovie(searchResults[e.target.id]);
        props.setMovieModalShow(false);
    }



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Movie Search
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form  className="d-flex" onSubmit={movieSearch} name="movie-search">
                    <Form.Control
                        onChange={handleChange}
                        name="search=input"
                        type="search"
                        placeholder="Search Movies"
                        className="me-2"
                        aria-label="Search"
                    />
                    <Button type="submit" variant="outline-secondary">Search</Button>
                </Form>

            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                { searchResults.length ?
                    <ListGroup className="w-100">
                        <ListGroup.Item className="p-0">
                            {searchResults.map((currentMovie,index) =>(
                            <Button id={index} className="d-flex justify-content-between w-100 mb-1" direction="horizontal" onClick={handleClick}>
                                <span style={{pointerEvents:"none"}} className="px-1">{currentMovie.title}</span> <span style={{pointerEvents:"none"}}>{currentMovie.release_date.split("-")[0]}</span>
                            </Button>
                            ))}
                        </ListGroup.Item>

                    </ListGroup>

                :
                    <p>No Results</p>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default MovieSearchModal;