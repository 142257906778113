import './css/App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./components/Home";
import CreatePairing from "./components/CreatePairing";
import Pairings from "./components/Pairings";
import Pairing from "./components/Pairing";
import Footer from "./components/Footer";
import Movie from "./components/Movie";
import CinemunchNavigation from "./components/CinemunchNavigation";
import FAQ from "./components/FAQ";
import About from "./components/About";

function App() {
        return (
            <div id="APP-CONTAINER" className="p-0 m-0 d-inline-flex flex-column w-100 overflow-hidden" >
                <Router>
                    <CinemunchNavigation />
                    <div id="MainContent" className="d-inline-flex justify-content-center w-100 p-0 pt-0 m-0">
                        <Routes>
                            <Route index element={<Home/>} />
                            <Route path="/" element={<Home/>} />
                            <Route path="create-pairing" element={<CreatePairing/>} />
                            <Route path="pairings" element={<Pairings/>} />
                            <Route path="pairing/:id" element={<Pairing/>}/>
                            <Route path="movie/:id" element={<Movie />} />
                            <Route path={"faq"} element={<FAQ />} />
                            <Route path={"about"} element={<About />} />
                        </Routes>
                    </div>
                    <Footer />
                </Router>
            </div>
        );
}

export default App;
