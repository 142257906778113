import {Card, Carousel, Nav, Stack} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {MovieDb} from "moviedb-promise";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const moviedb = new MovieDb(process.env.REACT_APP_TMDB_API_KEY);
import ('../css/PopularCarousel.css')


function PopularCarousel(){


    const [popular_movies, setPopularMovies] = useState(null);
    const [movie_cards, setMovieCards] = useState(null);
    const [pairings, setPairings] = useState(null);

    //On component load, get popular movies
    useEffect(() => {
        moviedb.moviePopular()
            .then((res) => {
                setPopularMovies(res.results)
            })
            .catch(console.error)
    }, []);

    //when popular movies are loaded, find pairings
    useEffect(() => {

        function getPairs(id) {
            return axios.get(`${API_URL}/pairings/movie/${id}`).then((response) => {return response.data.length});
        }

        async function mapPairings() {
            const pairs = await Promise.all(popular_movies.map(movie => getPairs(movie.id)));
            return pairs;
        }

        if (!!popular_movies){
            mapPairings().then((response) =>{setPairings(response)});
        }
    },[popular_movies]);

    // when pairings are created, create cards
    useEffect(() => {
        if (!!pairings){
            setMovieCards(popularList);
        }
        function  popularList() {
            let myGroups = [];
            for (let i = 0 ; i <= 4 ; i++){
                myGroups.push([]);
                for (let j = 0 ; j < 4; j++){
                    myGroups[i].push(popular_movies[i*4+j]);
                }
            }


            return (
                myGroups.map(function (currentGroup, i){
                    return (
                        <Carousel.Item>
                            <Stack direction={"horizontal"} className="carousel-page">
                                {currentGroup.map(function (currentMovie, j){
                                    let pUrl = "https://image.tmdb.org/t/p/w342/" + currentMovie.poster_path;
                                    return (
                                        <Card className="popular-card">
                                            <Nav.Link href={`/movie/${currentMovie.id}`}><Card.Img  id="poster" variant="top"  src={pUrl}/></Nav.Link>
                                            <Card.Footer>
                                                <div className=" w-100 fluid d-inline-flex justify-content-between">
                                                    <p> <i className="bi bi-link-45deg" />{pairings[(i*4)+j]}</p>
                                                    <p> <i className="bi bi-star-fill" /> {currentMovie.vote_average} </p>
                                                </div>
                                            </Card.Footer>
                                        </Card>
                                    )
                                })}
                            </Stack>
                        </Carousel.Item>
                    )
                })
            )
        }
    }, [pairings, popular_movies]);


    return (
        <div className="d-flex justify-content-center px-4">
        { (!!movie_cards) ?
                <Carousel id="Popular-Carousel" className="" touch={true} interval={null} slide={false} indicators={false} fade={true}>
                    {movie_cards}
                </Carousel>
            :
                <p>Loading... Please allow ~30 seconds for backend to start up (free tier server 😣)</p>
        }
        </div>
)

}

export default PopularCarousel;