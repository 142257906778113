import {Button, Card} from "react-bootstrap";

function MovieCard(props){
    let pUrl = "https://image.tmdb.org/t/p/w342/" + props.movie.poster_path;
    return (
        <Card style={{ width: '200px' }} className="flex-shrink-0">
            <Card.Img variant="top" src={pUrl} />
            <Card.Body className="d-inline-flex flex-column align-items-center py-1">
                <Button variant="dark" className="w-75" style={{height: "35px"}} onClick={() => props.setMovieModalShow(true)} >
                    <i className="bi bi-search"/>
                </Button>
            </Card.Body>
        </Card>
    )
}
export default MovieCard;