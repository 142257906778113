import "../css/About.css"
function About(){
    return(
        <div className="container my-5 text-center px-sm-4" id="About">
            <div>
                <h2 className="mb-5">About Cinemunch</h2>
                <p>Cinemunch is a project I built to gain more experience in web development. The goal of the project is to learn and develop a broader knowledge in fullstack web development.</p>
                <p>The website was built using the MERN stack, which includes MongoDB, Express, React, and Node.js. I designed the architecture such that the frontend and backend are completely separate, allowing for easier maintenance and scalability. The frontend gets data from the server using a RESTful API, which allows for efficient communication between the client and server.</p>
                <p>I chose to use MongoDB Atlas as the database for this project because it provides a reliable and scalable cloud-based database solution. The Express/Node.js server is hosted on AWS Elastic Beanstalk with AWS CodePipeline for continuous deployment of main branch.</p>
                <p>The React frontend is built using functional components and is hosted on AWS Amplify. The production deploy is automatically built from the GitHub repository main branch, which allows for easy deployment and version control.</p>
                <p>Movie and Recipe data is retrieved via the TMDB and Edamam API's respectively</p>
                <p>Thank you for taking the time to check out my project!</p>
            </div>

        </div>
    )
}
export default About;