import {Button, Card, Placeholder} from "react-bootstrap";
import recipe_place from "../static/recipe-placeholder.jpg";

function RecipePlaceholderCard(props){
    return(
        <Card style={{ width: '200px' }} className="flex-shrink-0" >
            <Card.Img variant="top" src={recipe_place} />
            <Card.Body className="d-inline-flex flex-column align-items-center py-1">
                <Placeholder as={Card.Title} animation="glow">
                    Choose a Recipe
                </Placeholder>
                <Button variant="dark" className="w-75" style={{height: "35px"}} onClick={() => props.setRecipeModalShow(true)} >
                    <i className="bi bi-search"/>
                </Button>
            </Card.Body>
        </Card>
    )
}
export default RecipePlaceholderCard;