import {Accordion} from "react-bootstrap";
import "../css/FAQ.css"
function FAQ(){
    return (
        <Accordion id="FAQ" className="container text-center pb-5 px-sm-4" alwaysOpen={true} >
            <h2 className="py-3">FAQ</h2>

            <Accordion.Item eventKey="0">
                <Accordion.Header>How do I create a movie and recipe pairing?</Accordion.Header>
                <Accordion.Body>
                    It's simple! Just choose a movie and a recipe on our <a href={"/create-pairing"}>pairing page.</a>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
                <Accordion.Header>What are the recent popular movies based on?</Accordion.Header>
                <Accordion.Body>
                    They are based on the most popular movies on TMDB.
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
                <Accordion.Header>How does the search bar work?</Accordion.Header>
                <Accordion.Body>
                    You can search for a specific movie and find all the pairings we have for that movie.
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
                <Accordion.Header>Can I see the ingredients for a specific pairing?</Accordion.Header>
                <Accordion.Body>
                    Yes, there is a page for each pairing that shows the ingredients, just click the title of the pairing you want to see.
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
                <Accordion.Header>What if I pick a movie that's not very popular?</Accordion.Header>
                <Accordion.Body>
                    No problem! Now you can be the first one to make a pairing
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
                <Accordion.Header>What if I choose a romantic movie, but I'm single?</Accordion.Header>
                <Accordion.Body>
                    We don't have the recipe for tissues, but we do have ice cream
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6">
                <Accordion.Header>Why did you make this?</Accordion.Header>
                <Accordion.Body>
                    I need a job someone hire me pls
                </Accordion.Body>
            </Accordion.Item>

        </Accordion>



    )
}

export default FAQ;