import {useEffect, useState} from "react";
import {Button, Stack} from "react-bootstrap";
import '../css/CreatePairing.css'
import MovieSearchModal from "./MovieSearchModal";
import RecipeSearchModal from "./RecipeSearchModal";
import MoviePlaceholderCard from "./MoviePlaceholderCard";
import MovieCard from "./MovieCard";
import RecipePlaceholderCard from "./RecipePlaceholderCard";
import RecipeCard from "./RecipeCard";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL

function CreatePairing(props) {
    const [movie, setMovie] = useState(null);
    const [recipe, setRecipe] = useState(null);
    const [movieModalShow, setMovieModalShow] = useState(false);
    const [recipeModalShow, setRecipeModalShow] = useState(false);
    const [description, setDescription] = useState("");
    const [heading, setHeading] = useState("Create your perfect pairing!");
    const [user, setUser] = useState("Anonymous");
    const [submitDisabled, setSubmitDisabled] = useState(false);

    useEffect(() => {
        if (props.movie){
            setMovie(props.movie);
        }
    },[]);

    function descriptionChange(e){
        setDescription(e.target.value);
    }

    function userChange(e){
        setUser(e.target.value);
    }

    function createPairing(e){
        setSubmitDisabled(true);
        console.log("Pairing button pressed")
        const recipe_id = recipe.uri.split("_").pop();

        axios.post(`${API_URL}/pairings/add`, {
            "movie_id": `${movie.id}`,
            "recipe_id": recipe_id,
            "description": description,
            "user_id": user
        }).then((response) => {
                console.log("pairing create returned");
                setSubmitDisabled(false);
                setHeading(response.data);
            }, (error) => {
                setHeading(error.response.data);
                setSubmitDisabled(false);
                console.error(error.response);
            });
    }


    return (
        <div className="create-pairing d-inline-flex flex-column align-items-center flex-shrink-0">
            <h2 id={"pairing-heading"} className="text-wrap">{heading}</h2>
            <Stack direction="horizontal" className="flex-shrink-0 justify-content-center py-2 py-lg-5 cards" >
                {!(movie == null) ?
                        <MovieCard movie={movie} className="movie" setMovieModalShow={setMovieModalShow} />
                    :
                        <MoviePlaceholderCard className="movie" setMovieModalShow={setMovieModalShow} />
                }
                <Stack id="pairing-icon-stack" className="flex-grow-0 justify-content-center align-items-center">
                    <p className="">{!(movie == null) ? (movie.title) : "" }</p>
                    { (!(movie == null) && !(recipe == null) && !(description.length === 0)) ?
                            <Button disabled={submitDisabled} onClick={createPairing} id={"pair-link-button"} className="bi bi-link-45deg link" style={{fontSize: "100px"}} />
                        :
                            <i id={"pair-link"} className="bi bi-link-45deg link" style={{fontSize: "100px"}}></i>}
                    <p>{!(recipe == null) ? (recipe.label) : "" }</p>
                </Stack>

                {!(recipe == null) ?
                        <RecipeCard recipe={recipe} className="recipe" setRecipeModalShow={setRecipeModalShow} />
                    :
                        <RecipePlaceholderCard setRecipeModalShow={setRecipeModalShow} className="recipe"  />
                }
            </Stack>
            <textarea
                onChange={userChange}
                required={true}
                id={"user"}
                rows={1}
                maxLength={20}
                className=" my-2 my-lg-3"
                placeholder="Enter your name..." >
            </textarea>
            <textarea
                onChange={descriptionChange}
                required={true}
                id={"pairing-description"}
                rows={10}
                maxLength={1000}
                className="my-2 my-lg-3"
                placeholder="Enter your description of the pairing..." >
            </textarea>
            <MovieSearchModal  setMovieModalShow={setMovieModalShow} setMovie={setMovie} show={movieModalShow} onHide={() => setMovieModalShow(false)}></MovieSearchModal>
            <RecipeSearchModal setRecipeModalShow={setRecipeModalShow} setRecipe={setRecipe} show={recipeModalShow} onHide={() => setRecipeModalShow(false)}></RecipeSearchModal>

        </div>

    );
}

export default CreatePairing;