import {Container, Nav, Navbar} from "react-bootstrap";
import logo from "../static/popcorn-svgrepo-com.svg";
import MovieSearchModal from "./MovieSearchModal";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "../css/CinemunchNavigation.css"

function CinemunchNavigation(){
    const [movieModalShow, setMovieModalShow] = useState(false);
    const [movie, setMovie] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!!movie){
            navigate(`/movie/${movie.id}`);
        }
    },[movie]);

    return (
        <Navbar id="Main-Navbar" className="main-navbar p-0 pb-3 mb-1 " variant="dark" collapseOnSelect expand="sm">
            <Container id="Navbar-Content" className="justify-content-between justify-content-sm-center w-100 py-2 full-width" style={{zIndex: "1"}} >
                <Navbar.Brand id="Nav-brand" href={"/"} className="me-sm-0">
                    <img src={logo} alt={"cinemunch logo"} width="30" height="30" className="d-inline-block align-top"/>
                    {` CINEMUNCH`}
                </Navbar.Brand>
                <MovieSearchModal  setMovieModalShow={setMovieModalShow} setMovie={setMovie} show={movieModalShow} onHide={() => setMovieModalShow(false)}></MovieSearchModal>

                <Navbar.Toggle aria-controls="responsive-navabar-menu" />
                <Navbar.Collapse id="responsive-navabar-menu" className="px-sm-5" style={{flexGrow: 0}}>
                    <Nav>
                        <Nav.Link href="/pairings">Pairings</Nav.Link>
                        <Nav.Link onClick={() => setMovieModalShow(true)}>
                            <i className="bi bi-search"></i>
                        </Nav.Link>
                        <Nav.Link href={"/create-pairing"}>
                            <i className="bi bi-link-45deg">New Pairing</i>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}


export default CinemunchNavigation;

