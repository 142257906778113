import {useEffect, useState} from "react";
import API_URL from "../index";
import axios from "axios";
import {Button, Nav, Pagination, Stack, Form} from "react-bootstrap";
import {MovieDb} from "moviedb-promise";
import "../css/Pairings.css"

// const moviedb = new MovieDb('27a8b3cd941691251e2e623dfdd25d91');
const moviedb = new MovieDb(process.env.REACT_APP_TMDB_API_KEY);

Pairings.defaultProps = {
    paginate : true,
    filter : true
};

function Pairings(props) {
    const [pairings, setPairings] = useState([]);
    const [movies, setMovies] = useState(null);
    const [recipes, setRecipes] = useState(null);
    const [pairingList, setPairingList] = useState(null);

    const [paginationPages, setPaginationPages] = useState(0);
    const [pairings_count, setPairingsCount] = useState(0);


    const [props_checked, setPropsChecked] = useState(false);

    //default params for pairings API call
    const [pairings_page, setPairingsPage] = useState(1);
    const [pairings_sort, setPairingsSort] = useState("-date_created");
    const [pairings_limit, setPairingsLimit] = useState(5);
    const [pairings_movie_id, setPairingsMovieId] = useState(0);


//on load, check if any props were passed in, if so, set the defaults to the props. then set props checked flag
    useEffect(() => {
        if (props.movie_id) {setPairingsMovieId(props.movie_id)}
        if (props.sort_by) {setPairingsSort(props.sort_by)}
        if (props.limit) {setPairingsLimit(props.limit)}
        if (props.page) {setPairingsPage(props.page)}
        setPropsChecked(true);
    }, []);

    useEffect(() => {
        if (props_checked) {

            //get count for current filter
            axios.get(`${API_URL}/pairings/count`,{
                params: {
                    movie_id : pairings_movie_id,
                    limit : pairings_limit,
                    page : pairings_page,
                    sort_by : pairings_sort,
                }
            })
                .then((res) => {
                    setPairingsCount(res.data);
                    setPaginationPages(Math.ceil(res.data/pairings_limit));
                })

            //get pairings for current filter
            axios.get(`${API_URL}/pairings`,{
                params: {
                    movie_id : pairings_movie_id,
                    limit : pairings_limit,
                    page : pairings_page,
                    sort_by : pairings_sort,
                }
            })
                .then((res) => {
                    setPairings(res.data);
                })
        }
    },[props_checked, pairings_movie_id,pairings_sort,pairings_limit,pairings_page]);


    //when pairings are retrieved, get info for each movie and recipe in each pairing
    useEffect(() => {
        const getData = async () => {
            const tempMovies = [];
            const tempRecipes = [];
            for (let pair of pairings){
                const movie = await moviedb.movieInfo(pair.movie_id);
                tempMovies.push(movie);
                const recipe = await axios.get(`${API_URL}/recipes/${pair.recipe_id}`);
                tempRecipes.push(recipe.data[0]);
            }
            setMovies(tempMovies);
            setRecipes(tempRecipes);
        }

        if (!!pairings){
            getData();
        }



    }, [pairings]);

    useEffect(() => {
        if (!movies || !recipes){
            return;
        }

        if (movies.length && recipes.length){
            setPairingList(pairingsList);
        }
    },[movies, recipes]);

    function increasePage(){
        if (pairings_page < paginationPages){
            setPairingsPage(pairings_page + 1);
        }
    }

    function decreasePage(){
        if (pairings_page > 1){
            setPairingsPage(pairings_page - 1);
        }
    }

    function pairingsList() {
        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
        };
        return (
            <div>
                {pairings.map(function (currentPair,i) {
                    let pUrl = "https://image.tmdb.org/t/p/w342/" + movies[i].poster_path;
                    return (
                        <Stack className=" p-card justify-content-center align-items-center text-center px-3" >
                            <Nav.Link href={`/pairing/${currentPair._id}`}><h4 className="w-100 pair-title">{movies[i].title} & {recipes[i].label}</h4></Nav.Link>

                            <p className="w-100">Paired by {currentPair.user_id} on {new Date(currentPair.date_created).toLocaleDateString(undefined,options)} </p>
                            <Stack direction={"horizontal"} className="justify-content-between card-content px-3">
                                <Nav.Link href={`/movie/${currentPair.movie_id}`} id="poster-container" ><img id="poster" src={pUrl} style={{borderRadius: "10px"}} alt={"Movie Poster"} /> </Nav.Link>
                                <p className="description">{currentPair.description}</p>
                                <a href={recipes[i].url} id="recipe-container"><img id="recipe" src={`data:image/jpeg;base64,${recipes[i].image}`} style={{borderRadius: "10px"}} alt={"Recipe Photo"} /></a>
                            </Stack>
                            {/*<p className="w-100 text-end">Likes : {currentPair.likes}</p>*/}
                            <hr className="w-100"/>
                        </Stack>
                    )
                })}
            </div>
        )
    }

    function paginationControls() {
        const pageLowest = (pairings_page <=1);
        const pageHighest = (pairings_page >= paginationPages);
        return (
            <Pagination className="align-items-center gap-1 justify-content-end pt-4 px-3">
                {pairings_page*pairings_limit - pairings_limit + 1}-{Math.min(pairings_page*pairings_limit,pairings_count)} of {pairings_count}
                <Button variant="dark" href={"#"}  onClick={decreasePage}  disabled={pageLowest} ><i className="bi bi-caret-left-fill" /></Button>
                <Button variant="dark" href={"#"} onClick={increasePage}  disabled={pageHighest} ><i className="bi bi-caret-right-fill" /></Button>
            </Pagination>
        )

    }

    function filterControls(){
        return(
            <div className="pb-5" id="filters">
                <Form className="text-center">
                    <Form.Label className="px-3">Sort By
                        <Form.Select aria-label="sort_by" onChange={handleSortChange}>
                            <option value="-date_created">date - newest first</option>
                            <option value="date_created">date - oldest first</option>
                            {/*<option value="-likes">popularity - highest</option>*/}
                            {/*<option value="likes">popularity - lowest</option>*/}
                        </Form.Select>
                    </Form.Label>
                    <Form.Label className="px-3"> Pairings Per Page
                        <Form.Select aria-label="pairings_per_page" onChange={handleLimitChange}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                        </Form.Select>
                    </Form.Label>
                </Form>

            </div>

        )
    }

    function handleSortChange(e){
        e.preventDefault();
        setPairingsSort(e.target.value);

        setPairingsPage(1);
    }

    function handleLimitChange(e){
        e.preventDefault();
        setPairingsLimit(e.target.value);

        setPairingsPage(1);

    }


    return (
        <div id="Pairings" className="justify-content-center pb-5">
            {
                // (!!pairingList) ?
                (paginationPages > 0) ?
                    <div>
                        {(props.filter) ?
                            <div>
                                {filterControls()}
                            </div>
                            :
                            <></>
                        }
                        {pairingList}
                        {(props.paginate) ?
                            <div>
                                {paginationControls()}
                            </div>

                            :
                            <></>
                        }
                    </div>

                    :
                    <div>
                        <Nav.Link href={`/create-pairing`}  ><p className="text-center">No Pairings Found<br/>Be the first to add one! </p></Nav.Link>
                    </div>


            }

        </div>
    )
}
export default Pairings;