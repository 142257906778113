import "../css/Footer.css"
import tmdb_logo from "../static/tmdb-logo.svg";
import edamam_logo from "../static/edamam.svg";
function Footer() {
    return (
        <div id="Footer" className="d-inline-flex align-items-center">
            <div className="content-wrap d-inline-flex flex-column justify-content-center align-items-center py-4">
                <div className="links pb-3 px-3">
                        <a href="/about" className="about"><li>About</li></a>
                        <a href="/faq" className="faq"><li>FAQ</li></a>
                        <a href="https://github.com/Wasy18" className="git"><li>GitHub</li></a>
                        <a href="https://www.themoviedb.org" className="tmdb"><li><img src={tmdb_logo} alt={"tmdb logo"} width="100"/></li></a>
                        <a href="https://www.edamam.com/" className="edam"><li><img src={edamam_logo} alt={"edamam logo"} width="100"/></li></a>
                    </div>
                <p>made by Tyson Wasylik 2023</p>


            </div>
        </div>
    )
}

export default Footer;