import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Form, ListGroup} from "react-bootstrap";
import {useState} from "react";
import axios from "axios";

function RecipeSearchModal(props) {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const API_URL = process.env.REACT_APP_EDAMAM_API_URL;
    const APP_ID = process.env.REACT_APP_EDAMAM_APP_ID;
    const APP_KEY = process.env.REACT_APP_EDAMAM_API_KEY;

    async function recipeSearch(e) {
        e.preventDefault();
        try {
            const res = await axios.get(`${API_URL}?type=public&q=${searchTerm}&app_id=${APP_ID}&app_key=${APP_KEY}&field=uri&field=label&field=image&field=source&field=url`);
            setSearchResults(res.data.hits);
            // return res.data;
        } catch (error) {
            console.error(error);
        }
    }

    function handleClick(e){
        props.setRecipe(searchResults[e.target.id].recipe);
        props.setRecipeModalShow(false);
    }

    function handleChange(e){
        setSearchTerm(e.target.value);
    }



    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Recipe Search
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="d-flex" onSubmit={recipeSearch} name="recipe-search">
                    <Form.Control
                        onChange={handleChange}
                        type="search"
                        placeholder="Search Recipes"
                        className="me-2"
                        aria-label="Search"
                    />
                    <Button type="submit" variant="outline-secondary">Search</Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                { searchResults.length ?
                    <ListGroup className="w-100">
                        <ListGroup.Item className="p-0 ">
                            {searchResults.map((currentRecipe,index) =>(
                                <Button id={index} className="d-flex justify-content-between w-100 mb-1" direction="horizontal" onClick={handleClick}>
                                    <span style={{pointerEvents:"none"}}>{currentRecipe.recipe.label}</span> <span style={{pointerEvents:"none"}}>{currentRecipe.recipe.source}</span>
                                </Button>
                            ))}
                        </ListGroup.Item>

                    </ListGroup>

                    :
                    <p>No Results</p>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default RecipeSearchModal;