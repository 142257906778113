import {Carousel, Nav} from "react-bootstrap";
import "../css/Home.css";
import React from "react";
import PopularCarousel from "./PopularCarousel";
import Pairings from "./Pairings";

function Home(){

    console.log(window.location.href);
    return (
        <div className="home">
            { (window.location.href== "https://cinemunch.netlify.app/") ?
                <h3 style={{color : "lightgray"}}> **NEW SELF HOSTED VERSION <a href={"https://cinemunch.tysonwasylik.com"}>HERE</a>**</h3>
                :
                <span />
            }

            <section className="px-3">Popular Movies</section>
            <hr />
            <PopularCarousel/>
            <div className="d-inline-flex w-100 justify-content-between mt-sm-1 mt-md-5">
                <section className="px-3">Recent Pairings</section>
                <Nav.Link href={`/pairings`} className="px-3">more</Nav.Link>
            </div>
            <hr />
            <Pairings paginate={false} filter={false} />
        </div>

    );
}

export default Home;