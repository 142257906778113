import {Button, Card, Placeholder} from "react-bootstrap";
import film_place from "../static/film-placeholder.jpg";

function MoviePlaceholderCard(props){
    return(
        <Card style={{ width: '200px' }} className="flex-shrink-0">
            <Card.Img variant="top" src={film_place} />
            <Card.Body className="d-inline-flex flex-column align-items-center py-1">
                <Placeholder as={Card.Title} animation="glow">
                    Choose a Movie
                </Placeholder>
                <Button variant="dark" className="w-75" style={{height: "35px"}} onClick={() => props.setMovieModalShow(true)} >
                    <i className="bi bi-search"/>
                </Button>
            </Card.Body>
        </Card>
    )
}
export default MoviePlaceholderCard;